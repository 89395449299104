import { Link, getRouteApi } from '@tanstack/react-router';
import { CurrentView } from '../../types';
import { useTranslation } from 'react-i18next';

type Props = {};

const routeApi = getRouteApi('/');

export function ViewPicker({}: Props) {
    const { t } = useTranslation();
    const navigate = routeApi.useNavigate();
    const search = routeApi.useSearch();
    const view = search.view;

    function setView(newView: CurrentView) {
        navigate({
            search: (old) => ({ ...old, view: newView }),
            replace: true,
        });
    }

    if (!view) return null;

    return (
        <div className="tabs tabs-boxed flex items-start flex-wrap flex-col md:flex-row justify-start md:justify-center whitespace-nowrap">
            <button
                onClick={() => setView('bubble')}
                className={`tab tab-xs xl:tab static ${
                    view === 'bubble' && 'tab-active'
                }`}
            >
                {t('chart')}
            </button>
            <button
                onClick={() => setView('bubble_and_map')}
                className={`tab tab-xs xl:tab static  ${
                    view === 'bubble_and_map' && 'tab-active'
                }`}
            >
                {t('chart-and-map')}
            </button>
            <button
                onClick={() => setView('map')}
                className={`tab tab-xs xl:tab static  ${
                    view === 'map' && 'tab-active'
                }`}
            >
                {t('map')}
            </button>
            <button className={`tab tab-xs xl:tab static`}>
                <Link to="/table" search={search}>
                    {t('table')}
                </Link>
            </button>
        </div>
    );
}
