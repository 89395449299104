import { useState } from 'react';
import { featureIsIncludedInCurrentFilter } from '../../helpers/filter';
import { useDataQuery } from '../../hooks/use-data.hook';
import { ApiConfig, DataOptions, TimeRangeMap, Indicator } from '../../types';
import { FilterFeatures } from '../FilterFeatures';
import { Table } from '../Table';
import { Spinner } from '../Spinner';
import { useTitle } from '../../hooks/use-title.hook';
import { useTranslation } from 'react-i18next';
import { getRouteApi, Link } from '@tanstack/react-router';
import { CloseIcon } from '../Icons/CloseIcon';

const routeApi = getRouteApi('/table/');

type Props = {
    config: ApiConfig;
    indicators: Indicator[];
};

export function TablePage(props: Props) {
    const { t } = useTranslation();
    const navigate = routeApi.useNavigate();
    const search = routeApi.useSearch();
    const selectedFeatures = search.selectedFeatures ?? [];

    const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');

    useTitle('Tabell');

    const featureFilter = search.featureFilter ?? [];

    const timeRangeMap = props.indicators.reduce((obj, curr) => {
        if (!curr.timeRanges) {
            console.error(
                `Timerange not found in indicator ${JSON.stringify(curr)}`
            );
            return obj;
        }

        return {
            ...obj,
            [curr.id]: curr.timeRanges,
        };
    }, {} as TimeRangeMap);

    const indicatorsMap = (props.indicators ?? []).reduce((obj, curr) => {
        obj[curr.id] = curr;
        return obj;
    }, {} as Record<string, Indicator>);

    const [targetOptions, setTargetOptions] = useState<DataOptions>({
        indicators: {
            xVar: indicatorsMap[
                search.indicators?.x ?? props.config?.defaultDataPackage.x
            ],
            yVar: indicatorsMap[
                search.indicators?.y ?? props.config?.defaultDataPackage.y
            ],
            rVar: indicatorsMap[
                search.indicators?.r ?? props.config?.defaultDataPackage.r
            ],
            cVar: indicatorsMap[
                search.indicators?.c ?? props.config?.defaultDataPackage.c
            ],
        },
        geoLevel: search.geoLevel,
    });

    const dataQuery = useDataQuery(targetOptions, indicatorsMap);

    if (!dataQuery.data) return <Spinner loading />;

    const { points, options } = dataQuery.data;

    let filteredPoints = points.filter((d) => {
        return featureIsIncludedInCurrentFilter(d, featureFilter);
    });

    if (showOnlySelected && selectedFeatures.length > 0) {
        filteredPoints = filteredPoints.filter((d) => {
            return selectedFeatures.includes(d.properties?.id);
        });
    }

    if (query) {
        filteredPoints = filteredPoints.filter((d) => {
            return (
                d.properties?.kommunnamn.toLowerCase().includes(query) ||
                d.properties?.[options.geoLevel].toLowerCase().includes(query)
            );
        });
    }

    const showOnlySelectedDisabled = selectedFeatures.length === 0;

    const Header = () => {
        const OnlySelectedToggle = () => {
            return (
                <div
                    className={
                        showOnlySelectedDisabled
                            ? ' tooltip tooltip-left tooltip-warning text-warning-content'
                            : ''
                    }
                    data-tip={t('inga-punkter-markerade')}
                >
                    <label
                        className={`flex flex-row gap-2 ${
                            showOnlySelectedDisabled
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer'
                        } text-sm items-center`}
                    >
                        <input
                            type="checkbox"
                            checked={
                                showOnlySelected && selectedFeatures.length > 0
                            }
                            disabled={showOnlySelectedDisabled}
                            onChange={() =>
                                setShowOnlySelected(!showOnlySelected)
                            }
                            className="checkbox checkbox-xs checkbox-primary"
                        />
                        {t('show-selected-only')}
                    </label>
                </div>
            );
        };

        return (
            <div className="flex flex-row gap-12 items-center p-6">
                <Link
                    to="/"
                    search={search}
                    className="flex items-center btn btn-sm btn-circle btn-primary"
                >
                    <CloseIcon fill="white" />
                </Link>
                <div className="flex flex-row gap-12 items-center justify-center  w-full ">
                    <div>
                        <input
                            type="text"
                            placeholder={t('search') as string}
                            className="input input-sm input-bordered w-full max-w-sm"
                            value={query}
                            onChange={(e) => {
                                setQuery(e.target.value.toLowerCase());
                            }}
                        />
                    </div>

                    <div className="flex gap-3">
                        <FilterFeatures
                            featureFilter={featureFilter}
                            onChangeFilter={(newFilter) => {
                                navigate({
                                    search: (old) => ({
                                        ...old,
                                        featureFilter: newFilter,
                                    }),
                                });
                            }}
                            onToggleZoomToFiltered={() => {
                                navigate({
                                    search: (old) => ({
                                        ...old,
                                        zoomToFiltered: !old.zoomToFiltered,
                                        animationBuster: new Date().getTime(),
                                    }),
                                });
                            }}
                            zoomToFiltered={search.zoomToFiltered}
                            points={points}
                            horizontal
                        />
                    </div>
                    <div>
                        <OnlySelectedToggle />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Header />
            <Table
                dataFeatures={filteredPoints}
                dataOptions={options}
                geoLevel={targetOptions.geoLevel}
                timeRangeMap={timeRangeMap}
                selectedFeatures={selectedFeatures}
                featureFilter={search.featureFilter}
                onSelectFeature={(_selectedFeatures) => {
                    navigate({
                        search: (old) => ({
                            ...old,
                            selectedFeatures: _selectedFeatures,
                        }),
                    });
                }}
            />
        </div>
    );
}

export default TablePage;
