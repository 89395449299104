import { FileSelectSmall } from '../FileViewer/FileSelectSmall';
import { FilterFeatures } from '../FilterFeatures';
import { GeoLevelPicker } from '../GeoLevelPicker';
import { BookIcon } from '../Icons/BookIcon';
import { Feature } from 'flagged';
import { DataPackageSelect } from './DataPackageSelect';
import { VarSelect } from '../VarSelect';
import { LanguageSwitch } from '../LanguageSwitch';
import { useFullScreen } from '../../hooks/use-fullscreen';
import { useState } from 'react';
import {
    ApiConfig,
    DataOptions,
    DataPackage,
    Indicator,
    TimeRangeMap,
} from '../../types';
import { DATA_PACKAGES, DataPackageMap } from '../../constants';
import { PackageIcon } from '../Icons/PackageIcon';
import { addSubLabel } from '../FileViewer/addSubLabel';
import { useTranslation } from 'react-i18next';
import { File } from '../FileViewer';
import { useIndicatorFiles } from '../../hooks/use-indicator-files';
import { XAxisIcon, YAxisIcon } from '../Icons';
import type { Feature as GeoJSONFeature } from 'geojson';
import { Data } from '../../hooks/use-data.hook';
import { Link, getRouteApi } from '@tanstack/react-router';
import { useHandleResize } from '../../hooks/use-handle-resize';
import { ChrevronLeft } from '../Icons/ChevronLeft';

const routeApi = getRouteApi('/');

export function Menu(props: {
    timeRangeMap: TimeRangeMap;
    timeRange: number[];
    config: ApiConfig;
    selectedDataPackage: DataPackage | null;
    dataPackages: DataPackage[];
    indicators: Indicator[];
    indicatorsMap: Record<string, Indicator>;
    onSetSelectedDatapackage: (dataPackage: DataPackage) => void;
    dataOptions: DataOptions;
    onSetXVar: (indicator: Indicator) => void;
    onSetYVar: (indicator: Indicator) => void;
    onSetRVar: (indicator: Indicator) => void;
    onSetCVar: (indicator: Indicator | null) => void;
    points: GeoJSONFeature[];
    onSetSelectedFeatures: (features: string[]) => void;
    data: Data;
}) {
    const fullscreenHandle = useFullScreen();
    const [showMenu, setShowMenu] = useState(false);
    const { t, i18n } = useTranslation();

    const navigate = routeApi.useNavigate();
    const search = routeApi.useSearch();

    const indicatorFilesPublic = useIndicatorFiles(
        props.indicators,
        (i) =>
            i.permissionLevel === undefined ||
            i.permissionLevel.includes('public')
    );
    const indicatorFilesHidden = useIndicatorFiles(
        props.indicators,
        (i) =>
            i.permissionLevel !== undefined &&
            i.permissionLevel.includes('user')
    );

    const dataPackageMap = props.dataPackages.reduce((obj, curr) => {
        obj[curr.id] = curr;
        return obj;
    }, {} as DataPackageMap);

    useHandleResize(() => {
        setShowMenu(false);
    });

    const publicFilesWithSubLabel = indicatorFilesPublic.map((f) =>
        addSubLabel({
            file: { ...f, meta: 'public' },
            geoLevel: search.geoLevel!,
            timeRangeMap: props.timeRangeMap,
            t,
            timeRange: props.timeRange,
        })
    );
    const hiddenFilesWithSubLabel = indicatorFilesHidden.map((f) =>
        addSubLabel({
            file: { ...f, meta: 'hidden' },
            geoLevel: search.geoLevel!,
            timeRangeMap: props.timeRangeMap,
            t,
            timeRange: props.timeRange,
        })
    );

    let filesWithSubLabel = publicFilesWithSubLabel;
    if (hiddenFilesWithSubLabel.length > 0) {
        filesWithSubLabel.push(
            { type: 'divider', title: 'Dolda indikatorer' } // TODO: i18n
        );
        filesWithSubLabel = [...filesWithSubLabel, ...hiddenFilesWithSubLabel];
    }

    function handleChangeFilter(newFilter: string[]) {
        props.onSetSelectedFeatures([]);
        navigate({
            replace: true,
            search: {
                ...search,
                animationBuster: new Date().getTime(),
                featureFilter: newFilter,
            },
        });
    }

    function handleToggleZoomToFiltered() {
        navigate({
            replace: true,
            search: {
                ...search,
                zoomToFiltered: !search.zoomToFiltered,
            },
        });
    }

    return (
        <div
            className={`w-12 ${
                !fullscreenHandle.active && 'xl:w-auto'
            } relative`}
        >
            <div
                style={{
                    transform: showMenu ? 'translate(87%, 0)' : undefined,
                }}
                className={`
                absolute
                transition-transform
                right-full
                -mr-12
                isolate
                z-10
                bg-base-200
                p-2
                shadow-xl
                rounded-lg
                max-w-sm
                w-96
                h-full
                ${
                    !fullscreenHandle.active
                        ? `
                    xl:static
                    xl:right-0
                    xl:mr-0
                    xl:bg-transparent
                    xl:p-0
                    xl:shadow-none
                    `
                        : undefined
                }
            `}
            >
                <MenuExpandButton
                    expanded={showMenu}
                    hidden={fullscreenHandle.active}
                    onClick={() => setShowMenu((value) => !value)}
                />

                {/* Left menu small */}
                <div
                    className={`
                    absolute
                    right-2
                    w-12
                    flex 
                    flex-col
                    h-full
                    gap-3
                    ${!showMenu ? '' : 'hidden'}
                    ${
                        !fullscreenHandle.active
                            ? `
                    xl:hidden
                    `
                            : 'justify-center xl:justify-start'
                    }
                `}
                >
                    <div className="gap-2 w-full rounded-lg bg-base-100 self-start flex flex-col items-center p-2">
                        <FileSelectSmall
                            className="btn-primary"
                            files={(
                                props.config?.dataPackages ?? DATA_PACKAGES
                            ).map((d): File => {
                                return {
                                    id: d.id,
                                    name: d.name[i18n.language] ?? d.name['sv'],
                                    type: 'file',
                                    meta:
                                        d.description?.[i18n.language] ??
                                        d.description?.['sv'],
                                };
                            })}
                            value={props.selectedDataPackage?.id ?? ''}
                            label={t('valj-datapaket')}
                            labelIcon={
                                <PackageIcon size={24} className="fill-white" />
                            }
                            onChange={(value) => {
                                const targetDataPackage = dataPackageMap[value];
                                props.onSetSelectedDatapackage(
                                    targetDataPackage
                                );
                            }}
                        />
                        <FileSelectSmall
                            files={filesWithSubLabel}
                            value={props.dataOptions.indicators.yVar.id}
                            label={t('valj-y')}
                            labelIcon={<YAxisIcon size={24} fill="white" />}
                            onChange={(value) => {
                                props.onSetYVar(props.indicatorsMap[value]);
                            }}
                        />

                        <FileSelectSmall
                            files={filesWithSubLabel}
                            value={props.dataOptions.indicators.xVar.id}
                            label={t('valj-x')}
                            labelIcon={<XAxisIcon size={24} fill="white" />}
                            onChange={(value) => {
                                props.onSetXVar(props.indicatorsMap[value]);
                            }}
                        />

                        <FileSelectSmall
                            files={filesWithSubLabel}
                            value={props.dataOptions.indicators.rVar.id}
                            label={t('valj-r')}
                            labelIcon={<span className="text-white">B</span>}
                            onChange={(value) => {
                                props.onSetRVar(props.indicatorsMap[value]);
                            }}
                        />

                        <FileSelectSmall
                            label={t('valj-c')}
                            labelIcon={<span className="text-white">F</span>}
                            files={[
                                {
                                    name: 'Kommun',
                                    type: 'file',
                                    id: 'kommun',
                                },
                                ...filesWithSubLabel,
                            ]}
                            value={
                                props.dataOptions.indicators.cVar
                                    ? props.dataOptions.indicators.cVar.id
                                    : 'kommun'
                            }
                            onChange={(value) => {
                                if (value === 'kommun') {
                                    // TODO: Set color mode
                                    return props.onSetCVar(null);
                                }
                                props.onSetCVar(props.indicatorsMap[value]);
                            }}
                        />
                    </div>

                    <div className="gap-2 w-full rounded-lg bg-base-100 self-start flex flex-col items-center p-2">
                        <GeoLevelPicker
                            vertical
                            dataOptions={props.dataOptions}
                            timeRange={props.timeRangeMap}
                            geoLevels={props.config.geoLevels}
                        />
                    </div>

                    <div className="gap-2 w-full rounded-lg bg-base-100 self-start flex flex-col items-center p-2">
                        <FilterFeatures
                            tiny
                            points={props.points}
                            featureFilter={search.featureFilter}
                            onToggleZoomToFiltered={handleToggleZoomToFiltered}
                            onChangeFilter={handleChangeFilter}
                            zoomToFiltered={search.zoomToFiltered}
                            showZoomToggle
                        />
                    </div>

                    <div className="gap-2 w-full rounded-lg bg-base-100 self-start flex flex-col items-center p-2">
                        <div
                            className="tooltip tooltip-right"
                            data-tip={t('om-verktyget')}
                        >
                            <Link to="/om">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-secondary  grid place-items-center w-full aspect-square"
                                >
                                    <BookIcon
                                        size={24}
                                        className="fill-white"
                                    />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Left menu full */}
                <div
                    className={`
                       flex
                       flex-col
                       gap-3
                        max-h-full
                        ${!showMenu && 'hidden'}
                        ${!fullscreenHandle.active && 'xl:flex'}
                   `}
                >
                    <div className="bg-base-100 rounded-lg p-3 flex flex-col relative gap-3 overflow-y-scroll overflow-x-hidden h-full">
                        <Feature name="SHOW_DATA_PACKAGES">
                            <DataPackageSelect
                                selectedDataPackage={props.selectedDataPackage}
                                setSelectedDatapackage={
                                    props.onSetSelectedDatapackage
                                }
                                dataPackageMap={dataPackageMap}
                                dataPackages={props.dataPackages}
                            />
                        </Feature>
                        <div className="z-50 pb-6">
                            <div className="mb-3 flex flex-row items-center gap-2">
                                <h2 className=" text-lg">{t('indikatorer')}</h2>
                            </div>
                            <VarSelect
                                indicatorsMap={props.indicatorsMap}
                                indicatorFiles={filesWithSubLabel}
                                setXVar={props.onSetXVar}
                                setYVar={props.onSetYVar}
                                setRVar={props.onSetRVar}
                                setCVar={props.onSetCVar}
                                xVar={props.dataOptions.indicators.xVar}
                                yVar={props.dataOptions.indicators.yVar}
                                rVar={props.dataOptions.indicators.rVar}
                                cVar={props.dataOptions.indicators.cVar}
                                data={props.data}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-center gap-2 items-center">
                        <GeoLevelPicker
                            geoLevels={props.config.geoLevels}
                            dataOptions={props.dataOptions}
                            timeRange={props.timeRangeMap}
                        />
                    </div>
                    <div className="bg-base-100 rounded-lg p-3 flex flex-col gap-3">
                        <h2 className="capitalize">{t('filter')}</h2>
                        <FilterFeatures
                            points={props.points}
                            featureFilter={search.featureFilter}
                            zoomToFiltered={search.zoomToFiltered}
                            onToggleZoomToFiltered={handleToggleZoomToFiltered}
                            onChangeFilter={handleChangeFilter}
                            showZoomToggle
                        />
                    </div>

                    <div className="flex justify-center items-center gap-3">
                        <Link to="/om">
                            <button
                                type="button"
                                className="btn btn-secondary flex gap-2"
                            >
                                <BookIcon className="fill-secondary-content" />
                                {t('om-verktyget')}
                            </button>
                        </Link>
                        <LanguageSwitch />
                    </div>
                </div>
            </div>
        </div>
    );
}

function MenuExpandButton(props: {
    expanded: boolean;
    hidden: boolean;
    onClick: () => void;
}) {
    return (
        <div
            className={`
                    absolute
                    left-full
                    top-16
                    -ml-2
                    mt-5
                    ${!props.expanded && 'rotate-180'}
                    ${!props.hidden && 'xl:hidden'}
                `}
        >
            <button
                onClick={props.onClick}
                type="button"
                className="btn btn-sm w-6 z-50 border-none bg-base-200 btn-secondary"
            >
                <div>
                    <ChrevronLeft fill="#e9498c" />
                </div>
            </button>
        </div>
    );
}
