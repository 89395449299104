import { create } from 'zustand';

type PlayerState = 'paused' | 'playing' | 'stopped';

type PlayerStore = {
    // State
    value: number;
    intValue: number;
    state: PlayerState;
    min: number;
    max: number;
    duration: number;
    animationBuster: number;

    // Internal refs (stored in store for persistence)
    lastTime: number | null;
    progress: number;

    // Actions
    initialize: (options: {
        min: number;
        max: number;
        duration: number;
        startValue?: number;
        onNewValue?: (args: { value: number; manual?: boolean }) => void;
    }) => void;
    play: () => void;
    pause: () => void;
    reset: () => void;
    playToggle: () => void;
    setValue: (value: number) => void;
    increment: () => void;
    decrement: () => void;
};

export const usePlayerStore = create<PlayerStore>((set, get) => ({
    // Initial state
    value: 0,
    intValue: 0,
    state: 'paused' as PlayerState,
    min: 0,
    max: 100,
    duration: 1000,
    lastTime: null,
    progress: 0,
    onNewValue: undefined,
    animationBuster: 0,

    initialize: (options) => {
        const startValue = options.startValue
            ? Math.max(options.startValue, options.min)
            : options.min;

        set({
            min: options.min,
            max: options.max,
            duration: options.duration,
            value: startValue,
            intValue: Math.ceil(startValue),
            progress: 0,
            lastTime: null,
            state: 'paused',
        });
    },

    play: () => {
        const { state } = get();
        if (state !== 'playing') {
            set({ state: 'playing' });
            animate();
        }
    },

    pause: () => {
        const { state } = get();
        if (state === 'playing') {
            set({ state: 'paused', lastTime: null });
        }
    },

    reset: () => {
        const { min } = get();
        set({
            state: 'paused',
            value: min,
            intValue: Math.ceil(min),
            lastTime: null,
            progress: 0,
            animationBuster: Date.now(),
        });
    },
    increment: () => {
        const { value, max, min } = get();
        const newValue = Math.min(value + 1, max);
        const p = (min - newValue) / (min - max);
        const newProgress = Math.max(0, Math.min(p, 1));
        set({
            value: newValue,
            intValue: Math.ceil(newValue),
            progress: newProgress,
            animationBuster: Date.now(),
        });
    },
    decrement: () => {
        const { value, min, max } = get();
        const newValue = Math.max(value - 1, min);
        const p = (min - newValue) / (min - max);
        const newProgress = Math.max(0, Math.min(p, 1));
        set({
            value: newValue,
            intValue: Math.ceil(newValue),
            progress: newProgress,
            animationBuster: Date.now(),
        });
    },

    playToggle: () => {
        const { state, play, pause, reset } = get();
        if (state === 'playing') {
            pause();
        } else if (state === 'paused') {
            play();
        } else if (state === 'stopped') {
            reset();
        }
    },

    setValue: (_value) => {
        const { min, max, state } = get();
        if (state === 'playing') {
            set({ state: 'paused' });
        }
        const p = (min - _value) / (min - max);
        const newProgress = Math.max(0, Math.min(p, 1));
        set({
            value: _value,
            intValue: Math.ceil(_value),
            progress: newProgress,
            animationBuster: Date.now(),
        });
    },
}));

const setLastTime = (time: number | null) =>
    usePlayerStore.setState({ lastTime: time });

const setProgress = (progress: number) => usePlayerStore.setState({ progress });

const setState = (state: PlayerState) => usePlayerStore.setState({ state });

function animate() {
    const store = usePlayerStore.getState();
    if (store.state !== 'playing') return;

    const currentTime = performance.now();

    if (!store.lastTime) {
        setLastTime(currentTime);
        requestAnimationFrame(animate);
        return;
    }

    const delta = currentTime - store.lastTime;
    setLastTime(currentTime);

    const newProgress = store.progress + delta / store.duration;
    setProgress(newProgress);

    const newValue =
        store.min +
        (store.max - store.min) * Math.max(0, Math.min(newProgress, 1));

    usePlayerStore.setState({
        value: newValue,
        intValue: Math.ceil(newValue),
    });

    if (newProgress < 1) {
        requestAnimationFrame(animate);
    } else {
        setState('stopped');
    }
}
