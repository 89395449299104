import { createFileRoute, redirect } from '@tanstack/react-router';
import { FormEvent, useEffect } from 'react';
import { searchSchema } from '.';
import { FEATURES } from '../constants';
import { useIsLoggedIn } from '../components/AuthProvider';

export const Route = createFileRoute('/login/github')({
    component: Index,
    loader: async () => {
        if (!FEATURES.GITHUB_LOGIN) {
            redirect({
                to: '/',
                search: searchSchema.parse({}),
                throw: true,
            });
        }
    },
});

let mounted = false;

function GitHubCallback() {
    const navigate = Route.useNavigate();
    const { recheckAuth } = useIsLoggedIn();
    console.log('RENDER');
    useEffect(() => {
        console.log('EFFECT!', mounted);
        if (mounted) return;
        mounted = true;
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if (code && state) {
            // Send code to backend
            fetch(`${import.meta.env.VITE_API_URL}/admin/login/github/callback`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ code, state }),
                credentials: 'include',
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('FETCHED', data);
                    // Handle the response from your backend. Maybe store an authentication token or set user data.
                    navigate({
                        to: '/',
                        search: searchSchema.parse({}),
                    });
                    recheckAuth();
                })
                .catch((e) => {
                    console.log('CATCHED', e);
                });
        }
        return () => {
            // mounted = false
        };
    }, []);

    return <div>Processing GitHub login...</div>;
}

function Index() {
    const handleLogin = (e: FormEvent<HTMLFormElement>) => {};

    return <GitHubCallback />;
}
