// import { INDICATORS } from './indicators';
import { DataPackage, Indicator } from './types';

export const TRANSITION_DURATION_MS = 1000;

export const CHARACTER_SET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖabcdefghijklmnopqrstuvwxyzåäö0123456789+-−*/%,.²:'.split(
        ''
    );

export const DEFAULT_DATA_PACKAGE: DataPackage = {
    id: 'socioekonomi',
    name: { sv: 'Socioekonomi' },
    x: 'ink.medel.tot',
    y: 'utb.gte3',
    r: 'folkbak.tot_tot',
    c: 'ink.medel.tot',
    description: {
        sv: 'Hur ser den socioekonomiska utvecklingen ut? Hur ser samband ut mellan utbildning och inkomster?',
    },
};

export const DATA_PACKAGES: DataPackage[] = [
    DEFAULT_DATA_PACKAGE,
    {
        id: 'lyckoparadoxen',
        name: { sv: 'Lyckoparadoxen' },
        x: 'ink.medel.tot',
        y: 'Flyttare 20-64 år, förvärvsarbetande året innan flytt',
        r: 'folkbak.tot_tot',
        c: 'Flyttare 20-64 år, förvärvsarbetande året innan flytt',
        description: {
            sv: 'Hur flyttar personer med arbete in till eller ut från områden? Hur samvarierar detta med inkomster i områden?',
        },
    },
    {
        id: 'segregation',
        name: { sv: 'Bostäder och boendeyta' },
        x: 'ink.medel.tot',
        y: 'Bostadsarea_total',
        r: 'pers.hr',
        c: 'Bostadsarea_total',
        description: {
            sv: 'Hur ser samband ut mellan boendeyta per person och inkomster i olika områden?',
        },
    },
    {
        id: 'kon_inkomst',
        name: { sv: 'Kön och inkomst' },
        x: 'ink.median.kvinnor',
        y: 'ink.median.män',
        r: 'folkbak.tot_tot',
        c: 'pers.ar',
        description: {
            sv: 'Hur är män och kvinnors inkomster i olika områden? Hur ser skillnaderna ut i olika områden?',
        },
    },
    {
        id: 'barn_utbildning',
        name: { sv: 'Barn och utbildning' },
        x: 'Andel_behöriga_gy_yrke_total',
        y: 'Andel_inskrivna_förskola_total',
        r: 'folkbak.tot_tot',
        c: 'Andel_hb_0_17_total',
        description: {
            sv: 'Hur stor andel barn går på förskola och får behörighet till gymnasiet? Hur ser samband ut mellan andel på förskola, gymnasiebehörighet och andel barn i resurssvaga familjer?',
        },
    },
];

// Same as above but structured for quick lookup by id.
export type DataPackageMap = {
    [id: string]: DataPackage;
};

export const FONT_FAMILY =
    'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

export const FEATURES = {
    USER_LOGIN: import.meta.env.VITE_FEATURE_USER_LOGIN === 'true',
    HUDDINGE_LOGIN: import.meta.env.VITE_FEATURE_HUDDINGE_LOGIN === 'true',
    GITHUB_LOGIN: import.meta.env.VITE_FEATURE_GITHUB_LOGIN === 'true',
    SHOW_DATA_PACKAGES: true,
    STATIC_TOOLTIP: import.meta.env.VITE_FEATURE_STATIC_TOOLTIP === 'true',
    SHOW_LABEL_PATHS: import.meta.env.VITE_FEATURE_SHOW_LABEL_PATHS === 'true',
    SELECT_ALL_BUTTON: import.meta.env.VITE_FEATURE_SELECT_ALL_BUTTON === 'true',
};

// Same as above but structured for quick lookup by id.
// type IndicatorMap = {
//     [id: string]: Indicator;
// };
// export const INDICATORS_MAP = INDICATORS.reduce((obj, curr) => {
//     obj[curr.id] = curr;
//     return obj;
// }, {} as IndicatorMap);
