import { Feature, GeoJsonProperties, Point } from 'geojson';
import { useEffect, useState } from 'react';
import { format } from '../../helpers/format';

import { useTimeRangeYears } from '../../hooks/use-timerangeyears';
import { DataOptions, GeoLevel, Indicator, TimeRangeMap } from '../../types';
import { DownloadIndicatorButton } from '../DownloadIndicatorButton';
import { useTranslation } from 'react-i18next';
import { SparkLine } from './SparkLine';

type Props = {
    dataOptions: DataOptions;
    dataFeatures: Feature<Point, GeoJsonProperties>[];
    geoLevel: GeoLevel;
    timeRangeMap: TimeRangeMap;
    selectedFeatures: string[];
    featureFilter: string[];
    onSelectFeature: (selectedFeatures: string[]) => void;
};

const formats: { [key: string]: string } = {
    heldagar: 'd',
    'per person': '.1f',
    '%': '.1%',
    '%d': 'd',
    st: 'd',
    tkr: 'd',
    m2: 'd',
};

export function Table({
    dataOptions,
    dataFeatures,
    geoLevel,
    timeRangeMap,
    selectedFeatures = [],
    onSelectFeature,
    featureFilter = [],
}: Props) {
    const { t, i18n } = useTranslation();

    const years = useTimeRangeYears({ dataOptions, timeRangeMap });

    const [selectedVar, setSelectedVar] = useState<Indicator>(
        dataOptions.indicators.xVar
    );

    useEffect(() => {
        setSelectedVar(dataOptions.indicators.xVar);
    }, [dataOptions]);

    const uniqoptions = Array.from(
        new Set(Object.values(dataOptions.indicators))
    ).filter((v) => v) as Indicator[];

    function HeaderRow() {
        return (
            <tr>
                <th className="sticky top-0"></th>
                <th className="sticky top-0">
                    <input
                        type="checkbox"
                        className="checkbox checkbox-xs"
                        checked={selectedFeatures.length > 0}
                        onChange={(e) => {
                            if (e.target.checked) {
                                onSelectFeature(
                                    dataFeatures.map(
                                        (f) => f.properties?.id ?? ''
                                    )
                                );
                            } else {
                                onSelectFeature([]);
                            }
                        }}
                    />
                </th>
                <th className="sticky top-0">{t('kommun')}</th>
                <th className="sticky top-0">{t('namn')}</th>
                <th className="sticky top-0"></th>
                {years.map((year) => {
                    return (
                        <th className="sticky top-0" key={year}>
                            {year}
                        </th>
                    );
                })}
            </tr>
        );
    }

    const sorted = dataFeatures
        .sort((a, b) => {
            if (geoLevel === 'kommun') {
                return 0;
            }
            return a.properties?.[geoLevel].localeCompare(
                b.properties?.[geoLevel],
                'sv'
            );
        })
        .sort((a, b) =>
            a.properties?.kommunnamn.localeCompare(
                b.properties?.kommunnamn,
                'sv'
            )
        );

    const selectedFeaturesSet = new Set(selectedFeatures);

    return (
        <div className="relative">
            <div className="sticky top-9 z-20 flex flex-row justify-center">
                <div className="tabs bg-white">
                    {uniqoptions.map((o) => {
                        return (
                            <div
                                key={o.id}
                                className={`tab tab-bordered flex gap-1 items-center ${
                                    selectedVar.id === o.id ? 'tab-active' : ''
                                }`}
                            >
                                <button
                                    onClick={() => {
                                        setSelectedVar(o);
                                    }}
                                >
                                    <small>
                                        {`${
                                            o.category?.[i18n.language] ??
                                            o.category?.['sv']
                                        } - ${
                                            o.label[i18n.language] ??
                                            o.label['sv']
                                        }, ${
                                            o.subLabel[i18n.language] ??
                                            o.subLabel['sv']
                                        }${
                                            o.unitDisplay
                                                ? `, ${o.unitDisplay}`
                                                : ''
                                        }`}
                                    </small>
                                </button>
                                <DownloadIndicatorButton
                                    data={{ points: dataFeatures }}
                                    geoLevel={dataOptions.geoLevel}
                                    indicator={o}
                                    featureFilter={featureFilter}
                                />
                            </div>
                        );
                    })}

                    <div
                        className={` flex gap-1 items-center tab tab-active underline`}
                    >
                        <a
                            href={`${
                                import.meta.env.VITE_API_URL
                            }/api/v1/data/indicators/${geoLevel}/all.csv?${uniqoptions
                                .map((o) => `id=${o.id}`)
                                .join('&')}`}
                        >
                            {t('download-all')} (.csv)
                        </a>
                    </div>
                </div>
            </div>
            <table className="table table-compact w-full relative overflow-x-auto">
                <thead>
                    <HeaderRow />
                </thead>
                <tbody>
                    {[
                        ...sorted.filter(
                            (d) => d.properties?.kommunnamn === 'Huddinge'
                        ),
                        ...sorted.filter(
                            (d) => d.properties?.kommunnamn !== 'Huddinge'
                        ),
                    ].map((f, i) => {
                        return (
                            <tr
                                onClick={() => {
                                    const features = selectedFeatures.includes(
                                        f.properties?.id
                                    )
                                        ? selectedFeatures.filter(
                                              (id) => id !== f.properties?.id
                                          )
                                        : [
                                              ...selectedFeatures,
                                              f.properties?.id,
                                          ];
                                    onSelectFeature(features);
                                }}
                                className={`${
                                    selectedFeatures.includes(f.properties?.id)
                                        ? 'active'
                                        : ''
                                } cursor-pointer`}
                                key={`${f.properties?.kommunnamn}-${
                                    f.properties?.[dataOptions.geoLevel]
                                }`}
                            >
                                <td>{i + 1}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={
                                            f.properties?.id
                                                ? selectedFeaturesSet.has(
                                                      f.properties.id
                                                  )
                                                : false
                                        }
                                        readOnly
                                        className="checkbox checkbox-xs"
                                    />
                                </td>
                                <td>{f.properties?.kommunnamn}</td>
                                <td>{f.properties?.[dataOptions.geoLevel]}</td>
                                <td>
                                    <SparkLine
                                        data={years.map((year) => {
                                            return {
                                                x: year,
                                                y: f.properties?.values[
                                                    selectedVar.id
                                                ][year],
                                            };
                                        })}
                                    />
                                </td>
                                {years.map((year) => {
                                    if (
                                        !f.properties?.values[selectedVar.id][
                                            year
                                        ]
                                    ) {
                                        return <td key={year}>-</td>;
                                    }
                                    return (
                                        <td key={year}>
                                            {format(
                                                formats[selectedVar.unitFormat]
                                            )(
                                                f.properties?.values[
                                                    selectedVar.id
                                                ][year]
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <HeaderRow />
                </tfoot>
            </table>
        </div>
    );
}
