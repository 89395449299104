import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { useDataQuery } from '../../hooks/use-data.hook';
import {
    ApiConfig,
    DataOptions,
    DataPackage,
    Indicator,
    TimeRangeMap,
} from '../../types';
import { ColorLegend } from '../ColorLegend';
import { TimeLine } from '../TimeLine';
import { ViewPicker } from '../ViewPicker';

import { getRouteApi } from '@tanstack/react-router';
import { Feature } from 'geojson';
import { useShallow } from 'zustand/shallow';
import { TRANSITION_DURATION_MS } from '../../constants';
import { getMinimumTimerange } from '../../get-minimumtimerange';
import { featureIsIncludedInCurrentFilter } from '../../helpers/filter';
import { useTitle } from '../../hooks/use-title.hook';
import { usePlayerStore } from '../../player.store';
import { Spinner } from '../Spinner';
import { Tooltip, TooltipFeatures } from '../Viz/Tooltip';
import { Menu } from './Menu';
import { Options } from './Options';

const routeApi = getRouteApi('/');

const VizScatter = lazy(() => import('../Viz/VizScatter'));
const VizMap = lazy(() => import('../Viz/VizMap'));

type Props = {
    config: ApiConfig;
    indicators: Indicator[];
};

let userNavigated = false;

export function Tool(props: Props) {
    useTitle('Huddingeanalysen');

    const navigate = routeApi.useNavigate();
    const search = routeApi.useSearch();
    const searchRef = useRef(search)
    searchRef.current = search

    const [activeFeatureId, setActiveFeatureId] = useState<string | null>(null);
    const [showScatterPaths, setShowScatterPaths] = useState<boolean>(false);
    const [showLabelsSelected, setShowLabelsSelected] = useState<boolean>(true);
    const [tooltipFeatures, setTooltipFeatures] =
        useState<TooltipFeatures | null>(null);

    const [notSelectedOpacity, setNotSelectedOpacity] = useState<{
        features: number;
        lines: number;
    }>({ features: 0.4, lines: 0.2 });
    const [labelSize, setLabelSize] = useState<number>(16);
    const [circleSize, setCircleSize] = useState<{ min: number; max: number }>({
        min: 4,
        max: 15,
    });

    const playerStore = usePlayerStore(
        useShallow((state) => ({
            intValue: state.intValue,
            initialize: state.initialize,
            state: state.state,
            animationBuster: state.animationBuster,
            pause: state.pause,
        }))
    );

    const timeRangeMap = props.indicators.reduce((obj, curr) => {
        if (!curr.timeRanges) {
            console.error(
                `Timerange not found in indicator ${JSON.stringify(curr)}`
            );
            return obj;
        }

        return {
            ...obj,
            [curr.id]: curr.timeRanges,
        };
    }, {} as TimeRangeMap);

    const indicatorsMap = props.indicators.reduce((obj, curr) => {
        obj[curr.id] = curr;
        return obj;
    }, {} as Record<string, Indicator>);

    const xVar =
        indicatorsMap[search.indicators?.x ?? ''] ??
        indicatorsMap[props.config.defaultDataPackage.x];
    const yVar =
        indicatorsMap[search.indicators?.y ?? ''] ??
        indicatorsMap[props.config.defaultDataPackage.y];
    const rVar =
        indicatorsMap[search.indicators?.r ?? ''] ??
        indicatorsMap[props.config.defaultDataPackage.r];
    let cVar: Indicator | null = null;

    if (search.indicators?.c !== null) {
        cVar =
            indicatorsMap[search.indicators?.c ?? ''] ??
            indicatorsMap[props.config.defaultDataPackage.c];
    }

    const targetOptions: DataOptions = {
        indicators: {
            xVar,
            yVar,
            rVar,
            cVar,
        },
        geoLevel: search.geoLevel,
    };

    const dataQuery = useDataQuery(targetOptions, indicatorsMap);

    useEffect(() => {
        if (!dataQuery.data) {
            return;
        }

        const timeRange = getMinimumTimerange({
            dataOptions: dataQuery.data.options,
            timeRangeMap,
        });

        navigate({
            replace: true,
            search: {
                ...search,
                year: timeRange[0],
                animationBuster: new Date().getTime(),
                indicators: {
                    x: dataQuery.data.options.indicators.xVar.id,
                    y: dataQuery.data.options.indicators.yVar.id,
                    r: dataQuery.data.options.indicators.rVar.id,
                    c: dataQuery.data.options.indicators.cVar?.id ?? null,
                },
            },
        });

        playerStore.initialize({
            min: timeRange[0],
            max: timeRange[1],
            duration: TRANSITION_DURATION_MS * (timeRange[1] - timeRange[0]),
            startValue: timeRange[0],
        });

        userNavigated = false;
    }, [dataQuery.data]);

    const kommunQuery = useDataQuery(
        {
            ...targetOptions,
            geoLevel: 'kommun',
        },
        indicatorsMap
    );

    const selectedFeatures = search.selectedFeatures ?? [];
    function setSelectedFeatures(features: string[]) {
        const search = searchRef.current
        navigate({
            replace: true,
            search: {
                ...search,
                selectedFeatures: features,
            },
        });
    }

    const activeFeatureIdRef = useRef<string | null>(null);
    if (activeFeatureIdRef.current !== activeFeatureId) {
        activeFeatureIdRef.current = activeFeatureId ?? null;
    }
    const selectedFeaturesRef = useRef<string[]>(selectedFeatures);
    if (selectedFeaturesRef.current !== selectedFeatures) {
        selectedFeaturesRef.current = selectedFeatures;
    }

    useEffect(() => {
        const handleGlobalClick = () => {
            const id = activeFeatureIdRef.current;
            if (!id) return;

            if (!selectedFeaturesRef.current.includes(id)) {
                setSelectedFeatures([...selectedFeaturesRef.current, id]);
            } else {
                setSelectedFeatures(
                    selectedFeaturesRef.current.filter((_id) => _id !== id)
                );
            }
        };

        window.addEventListener('click', handleGlobalClick);

        return () => {
            window.removeEventListener('click', handleGlobalClick);
        };
    }, []);

    if (dataQuery.error) {
        return (
            <div className="fixed inset-0 grid place-items-center">
                <div className="flex flex-col gap-1">
                    Oops!
                    <a href="/" className="link-secondary">
                        reset
                    </a>
                </div>
            </div>
        );
    }

    if (!dataQuery.data) {
        return <Spinner loading={true} />;
    }

    const timeRange = getMinimumTimerange({
        dataOptions: dataQuery.data.options,
        timeRangeMap,
    });

    const dataPackages = props.config.dataPackages;

    const selectedDataPackage =
        dataPackages.find((datapackage) => {
            if (!xVar || !yVar || !rVar) {
                return false;
            }
            return (
                xVar.id === datapackage?.x &&
                yVar.id === datapackage?.y &&
                rVar.id === datapackage?.r &&
                (!cVar || cVar.id === datapackage?.c)
            );
        }) ?? null;

    function handleSetSelectedDatapackage(newPackage: DataPackage) {
        const newPackageMissingIndicators =
            !newPackage.x || !newPackage.y || !newPackage.r;
        const indicatorsMapMissingIndicators = [
            newPackage.x,
            newPackage.y,
            newPackage.r,
        ].some((d) => !indicatorsMap[d]);

        if (newPackageMissingIndicators || indicatorsMapMissingIndicators) {
            console.error(
                `Missing indicators in newPackage:\n${JSON.stringify(
                    newPackage,
                    null,
                    4
                )}\n\nor indicatorsMap:\n${JSON.stringify(
                    indicatorsMap,
                    null,
                    4
                )}`
            );
            return;
        }

        playerStore.pause();

        const cVar = newPackage.c ? indicatorsMap[newPackage.c] : null;

        userNavigated = true;

        navigate({
            replace: true,
            search: {
                ...search,
                indicators: {
                    x: newPackage.x,
                    y: newPackage.y,
                    r: newPackage.r,
                    c: newPackage.c,
                },
                colorType: cVar?.colorType ?? undefined,
                scaleType: cVar?.scaleType ?? undefined,
            },
        });
    }

    let { points, polygons, options } = dataQuery.data;

    function setVar(indicator: Indicator, key: string) {
        userNavigated = true;

        const newVar = indicatorsMap[indicator.id];

        let colorType = search.colorType;
        let scaleType = search.scaleType;

        if (key === 'c') {
            colorType = newVar.colorType ?? colorType;
            scaleType = newVar.scaleType ?? scaleType;
        }

        navigate({
            replace: true,
            search: {
                ...search,
                indicators: {
                    ...search.indicators,
                    [key]: indicator.id,
                },
                colorType,
                scaleType,
            },
        });
    }

    const handleTooltip = ({ object }: { object: Feature }) => {
        if (!object) return null;
        if (!featureIsIncludedInCurrentFilter(object, search.featureFilter)) {
            return null;
        }

        const kommunPoint = kommunQuery.data?.points.find((kommun) => {
            return kommun.properties?.KOM_KOD === object.properties?.kommun;
        });
        setTooltipFeatures({
            hovered: object,
            kommun: kommunPoint,
        });

        return { style: { backgroundColor: 'rgba(0, 0, 0, 0)' }, html: `` };
    };

    const handleHover = (id: string | null) => {
        setActiveFeatureId(id);
        const object = points.find((d) => d.properties?.id === activeFeatureId);
        if (object) {
            handleTooltip({ object });
        }
    };

    if (!activeFeatureId && tooltipFeatures) {
        setTooltipFeatures(null);
    }

    return (
        <div className="h-full p-3 bg-base-300 flex flex-col gap-3 overflow-x-hidden">
            <div className="flex flex-row gap-3 flex-1 h-full min-h-0 relative">
                <Menu
                    indicators={props.indicators ?? []}
                    config={props.config}
                    dataPackages={dataPackages}
                    selectedDataPackage={selectedDataPackage}
                    timeRangeMap={timeRangeMap}
                    timeRange={timeRange}
                    dataOptions={dataQuery.data.options}
                    onSetXVar={(indicator) => setVar(indicator, 'x')}
                    onSetYVar={(indicator) => setVar(indicator, 'y')}
                    onSetRVar={(indicator) => setVar(indicator, 'r')}
                    onSetCVar={(indicator) => {
                        if (indicator) {
                            setVar(indicator, 'c');
                        } else {
                            userNavigated = true;
                            navigate({
                                replace: true,
                                search: {
                                    ...search,
                                    indicators: {
                                        ...search.indicators,
                                        c: null,
                                    },
                                },
                            });
                        }
                    }}
                    data={dataQuery.data}
                    indicatorsMap={indicatorsMap}
                    onSetSelectedDatapackage={handleSetSelectedDatapackage}
                    onSetSelectedFeatures={setSelectedFeatures}
                    points={points}
                />

                <div className="grid grid-cols-1 grid-rows-[1fr_auto] flex-1 gap-3">
                    {/* Main visualization */}
                    <div
                        className={` h-full flex-1 rounded-lg grid grid-cols-2 grid-rows-1 relative`}
                    >
                        {activeFeatureId && tooltipFeatures && (
                            <div className="absolute right-1 z-10 bottom-2 pointer-events-none">
                                <Tooltip
                                    tooltipFeatures={tooltipFeatures}
                                    geoLevel={dataQuery.data.options.geoLevel}
                                    dataOptions={dataQuery.data.options}
                                    year={playerStore.intValue}
                                />
                            </div>
                        )}
                        <Suspense fallback={<Spinner loading />}>
                            {(search.view === 'bubble' ||
                                search.view === 'bubble_and_map') && (
                                <div
                                    className="relative h-full bg-white rounded-tl-lg rounded-bl-lg "
                                    style={{
                                        gridColumn:
                                            search.view === 'bubble'
                                                ? 'span 2'
                                                : 'span 1',
                                    }}
                                >
                                    <VizScatter
                                        key={search.view}
                                        dataOptions={options}
                                        dataFeatures={points}
                                        kommunData={kommunQuery.data}
                                        year={playerStore.intValue}
                                        selectedFeatures={selectedFeatures}
                                        onClearSelection={() =>
                                            setSelectedFeatures([])
                                        }
                                        onSelectAll={() => {
                                            setSelectedFeatures(
                                                points
                                                    .filter((d) =>
                                                        featureIsIncludedInCurrentFilter(
                                                            d,
                                                            search.featureFilter
                                                        )
                                                    )
                                                    .map((d) =>
                                                        String(d.properties?.id)
                                                    )
                                            );
                                        }}
                                        showScatterPaths={showScatterPaths}
                                        notSelectedOpacity={notSelectedOpacity}
                                        circleSize={circleSize}
                                        showLabelsSelected={showLabelsSelected}
                                        labelSize={labelSize}
                                        viewStateInUrl={true}
                                        timeRangeMap={timeRangeMap}
                                        animationBuster={Math.max(
                                            playerStore.animationBuster,
                                            search.animationBuster ?? 0
                                        )}
                                        featureFilter={search.featureFilter}
                                        zoomToFiltered={search.zoomToFiltered}
                                        colorScale={search.colorScale}
                                        colorType={search.colorType}
                                        scaleType={search.scaleType}
                                        setActiveFeatureId={handleHover}
                                        activeFeatureId={activeFeatureId}
                                    />
                                </div>
                            )}
                            {(search.view === 'map' ||
                                search.view === 'bubble_and_map') && (
                                <div
                                    className="relative h-full bg-white rounded-tr-lg rounded-br-lg overflow-hidden "
                                    style={{
                                        gridColumn:
                                            search.view === 'map'
                                                ? 'span 2'
                                                : 'span 1',
                                    }}
                                >
                                    <VizMap
                                        key={search.view}
                                        dataOptions={options}
                                        dataFeatures={points}
                                        polygons={polygons}
                                        kommunData={kommunQuery.data}
                                        year={playerStore.intValue}
                                        selectedFeatures={selectedFeatures}
                                        onClearSelection={() =>
                                            setSelectedFeatures([])
                                        }
                                        onSelectAll={() => {
                                            setSelectedFeatures(
                                                points
                                                    .filter((d) =>
                                                        featureIsIncludedInCurrentFilter(
                                                            d,
                                                            search.featureFilter
                                                        )
                                                    )
                                                    .map((d) =>
                                                        String(d.properties?.id)
                                                    )
                                            );
                                        }}
                                        notSelectedOpacity={notSelectedOpacity}
                                        circleSize={circleSize}
                                        showLabelsSelected={showLabelsSelected}
                                        labelSize={labelSize}
                                        viewStateInUrl={true}
                                        timeRangeMap={timeRangeMap}
                                        animationBuster={Math.max(
                                            playerStore.animationBuster,
                                            search.animationBuster ?? 0
                                        )}
                                        featureFilter={search.featureFilter}
                                        zoomToFiltered={search.zoomToFiltered}
                                        mapType={search.mapType}
                                        onViewStateChange={(viewState) => {
                                            navigate({
                                                replace: true,
                                                search: {
                                                    ...search,
                                                    ...viewState,
                                                },
                                            });
                                        }}
                                        colorScale={search.colorScale}
                                        colorType={search.colorType}
                                        scaleType={search.scaleType}
                                        activeFeatureId={activeFeatureId}
                                        setActiveFeatureId={handleHover}
                                    />
                                </div>
                            )}
                        </Suspense>
                    </div>
                    <div className="flex">
                        <div className="flex-1 flex justify-center">
                            <ViewPicker />
                        </div>
                        <Options
                            circleSize={circleSize}
                            setCircleSize={setCircleSize}
                            labelSize={labelSize}
                            setLabelSize={setLabelSize}
                            notSelectedOpacity={notSelectedOpacity}
                            setNotSelectedOpacity={setNotSelectedOpacity}
                            showLabelsSelected={showLabelsSelected}
                            setShowLabelsSelected={setShowLabelsSelected}
                            showScatterPaths={showScatterPaths}
                            setShowScatterPaths={setShowScatterPaths}
                            selectedFeatures={selectedFeatures}
                        />
                    </div>
                </div>
            </div>
            <div className="flex gap-3">
                <div className="bg-base-100 rounded-lg flex-1">
                    <TimeLine key={timeRange.join('-')} timeRange={timeRange} />
                </div>
                {cVar && (
                    <div className="bg-base-100 rounded-lg p-3">
                        <ColorLegend
                            markValue={
                                tooltipFeatures?.hovered?.properties?.values[
                                    options.indicators.cVar?.id ?? ''
                                ][playerStore.intValue]
                            }
                            showHeader={false}
                            cVar={options.indicators.cVar}
                            data={points}
                            featureFilter={search.featureFilter}
                            zoomToFiltered={search.zoomToFiltered}
                            colorScale={search.colorScale}
                            colorType={search.colorType}
                            scaleType={search.scaleType}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
