import { Feature } from 'geojson';
import { useState } from 'react';
import { FilterIcon } from '../Icons';
import { Portal } from '../Portal';
import { useTranslation } from 'react-i18next';

export function FilterFeatures(props: {
    points: Feature[];
    featureFilter: string[];
    zoomToFiltered: boolean;
    onChangeFilter: (newFeatureFilter: string[]) => void;
    onToggleZoomToFiltered: () => void;
    showZoomToggle?: boolean;
    tiny?: boolean;
    horizontal?: boolean;
}) {
    const showZoomToggle = props.showZoomToggle ?? false;
    const horizontal = props.horizontal ?? false;

    const { t } = useTranslation();

    const [showFilterModal, setShowFilterModal] = useState(false);

    function handleClick(value: string) {
        const newFilter = props.featureFilter.includes(value)
            ? props.featureFilter.filter((v) => v !== value)
            : [...props.featureFilter, value];

        handleChange(newFilter);
    }

    function handleChange(newFilter: string[]) {
        if (props.onChangeFilter) {
            props.onChangeFilter(newFilter);
        }
    }

    const kommunSet = new Set<string>();
    for (const f of props.points) {
        kommunSet.add(f.properties?.kommunnamn);
    }

    const kommuner = Array.from(kommunSet);

    const quickSelects = [
        { label: 'Huddinge', value: ['Huddinge'] },
        { label: 'Stockholms län', value: [] },
    ];

    return (
        <>
            <div
                className={`flex gap-2 ${horizontal ? '' : 'flex-col w-full'} ${
                    props.tiny && `items-center gap-3`
                }`}
            >
                {!props.tiny && (
                    <div
                        className={`tabs tabs-boxed flex ${
                            horizontal ? 'flex-row' : 'flex-col items-start'
                        }`}
                    >
                        {quickSelects.map((q) => {
                            return (
                                <button
                                    key={q.label}
                                    onClick={() => {
                                        handleChange(q.value);
                                    }}
                                    className={`tab justify-start ${
                                        horizontal ? '' : 'w-full'
                                    } ${
                                        props.featureFilter.toString() ===
                                        q.value.toString()
                                            ? 'tab-active'
                                            : ''
                                    }`}
                                >
                                    {q.label}
                                </button>
                            );
                        })}

                        <button
                            onClick={() => {
                                setShowFilterModal(true);
                            }}
                            className={`tab ${
                                horizontal ? '' : 'w-full justify-start'
                            } ${
                                !quickSelects.some(
                                    (q) =>
                                        props.featureFilter.toString() ===
                                        q.value.toString()
                                )
                                    ? 'tab-active'
                                    : ''
                            }`}
                        >
                            {t('Valj-kommuner')}
                        </button>
                    </div>
                )}
                {props.tiny && (
                    <div
                        className="tooltip  tooltip-right"
                        data-tip={t('Valj-kommuner')}
                    >
                        <button
                            onClick={() => {
                                setShowFilterModal(true);
                            }}
                            className="btn btn-sm btn-primary grid place-items-center w-full aspect-square"
                        >
                            <FilterIcon size={24} fill="white" />
                        </button>
                    </div>
                )}
                {props.tiny && (
                    <div className={`tabs tabs-sm tabs-boxed`}>
                        {quickSelects.map((q) => {
                            return (
                                <button
                                    key={q.label}
                                    onClick={() => {
                                        handleChange(q.value);
                                    }}
                                    className={`tooltip  tooltip-right tab tab-xs ${
                                        props.featureFilter.toString() ===
                                        q.value.toString()
                                            ? 'tab-active'
                                            : ''
                                    }`}
                                    data-tip={q.label}
                                >
                                    {q.label[0]}
                                </button>
                            );
                        })}
                    </div>
                )}
                {showZoomToggle && (
                    <div
                        className={`${props.tiny && 'tooltip tooltip-right'}`}
                        data-tip={t('zooma-till-urval')}
                    >
                        <label
                            className={`flex flex-row gap-2 text-sm items-center`}
                        >
                            <input
                                type="checkbox"
                                checked={props.zoomToFiltered}
                                onChange={
                                    () => props.onToggleZoomToFiltered()
                                    // navigate({
                                    //     search: (old) => ({
                                    //         ...old,
                                    //         zoomToFiltered: !zoomToFiltered,
                                    //         animationBuster:
                                    //             new Date().getTime(),
                                    //     }),
                                    // })
                                }
                                className="checkbox checkbox-primary checkbox-xs"
                            />
                            {!props.tiny && t('zooma-till-urval')}
                        </label>
                    </div>
                )}
            </div>
            {showFilterModal && (
                <Portal>
                    <FilterModal
                        featureFilter={props.featureFilter}
                        onClose={() => {
                            setShowFilterModal(false);
                        }}
                        kommuner={kommuner}
                        onClick={handleClick}
                        onClear={() => {
                            props.onChangeFilter([]);
                        }}
                    />
                </Portal>
            )}
        </>
    );
}

function FilterModal(props: {
    onClose: () => void;
    kommuner: string[];
    featureFilter: string[];
    onClick: (value: string) => void;
    onClear: () => void;
}) {
    const { t } = useTranslation();

    return (
        <div className="fixed inset-0 bg-black bg-opacity-20 isolate grid place-items-center pointer-events-none z-50">
            <div
                className="fixed inset-0 pointer-events-auto cursor-pointer"
                onClick={() => {
                    props.onClose();
                }}
            />

            <div className="card bg-base-100 roundex-xl p-6 w-full max-w-md max-h-full  pointer-events-auto">
                <div className="mb-6 flex gap-3 justify-between">
                    <span>{t('en-eller-flera-kommuner')}</span>
                    {props.featureFilter.length > 0 && (
                        <button
                            onClick={props.onClear}
                            className="btn btn-xs btn-secondary"
                        >
                            {t('rensa')}
                        </button>
                    )}
                </div>
                <ul className="menu bg-base-100 h-96 max-h-full overflow-scroll no-scrollbar">
                    {props.kommuner
                        .sort((a, b) => a.localeCompare(b, 'sv'))
                        .map((kommun) => ({
                            id: kommun,
                            value: kommun,
                            label: kommun,
                        }))
                        .map((option) => (
                            <li key={option.id}>
                                <button
                                    className={`${
                                        props.featureFilter.includes(
                                            option.value
                                        )
                                            ? 'active'
                                            : undefined
                                    }`}
                                    onClick={() => {
                                        props.onClick(option.value);
                                    }}
                                >
                                    {option.label}
                                </button>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
}
