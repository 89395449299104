import { Feature } from 'geojson';
import { DataOptions, GeoLevel, Indicator } from '../../types';
import { format } from '../../helpers/format';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type TooltipFeatures = {
    kommun?: Feature;
    hovered?: Feature;
};

type Props = {
    tooltipFeatures: TooltipFeatures;
    geoLevel: GeoLevel;
    dataOptions: DataOptions;
    year: number;
};

const formatsTooltip: { [key: string]: string } = {
    heldagar: 'd',
    'per person': '.1f',
    '%': '.1%',
    '%d': 'd',
    st: 'd',
    tkr: 'd',
    m2: 'd',
};

export function Tooltip({
    tooltipFeatures,
    geoLevel,
    dataOptions,
    year,
}: Props) {
    const { t, i18n } = useTranslation();

    function HeaderRow() {
        return (
            <tr>
                <th className="sticky top-0"></th>
                <th className="sticky top-0">{t('indikator')}</th>
                <th className="sticky top-0">{t('tooltip-omradet')}</th>
                <th className="sticky top-0">{t('tooltip-kommunen')}</th>
            </tr>
        );
    }

    const { hovered, kommun } = tooltipFeatures;

    const uniqoptions = Array.from(
        new Set(Object.values(dataOptions.indicators))
    ).filter((v) => v) as Indicator[];

    function getHeader(
        geoLevel: GeoLevel,
        hovered: Feature | undefined,
        kommun: Feature | undefined
    ) {
        if (geoLevel === 'kommun') {
            return hovered?.properties?.kommunnamn;
        }

        if (geoLevel === 'nyko6') {
            return `${hovered?.properties?.name} (${hovered?.properties?.kommunnamn})`;
        }

        return `${hovered?.properties?.[geoLevel]} (${hovered?.properties?.kommunnamn})`;
    }

    return (
        <div className="card border shadow-xl bg-base-100 text-base-content p-3">
            <h2 className="text-lg mb-2">
                {getHeader(geoLevel, hovered, kommun)} {year}
            </h2>

            <table className="table table-compact w-full relative overflow-x-auto">
                <thead>
                    <HeaderRow />
                </thead>
                <tbody>
                    {uniqoptions.map((indicator, i) => {
                        if (!hovered?.properties?.values[indicator.id]) {
                            return;
                        }
                        return (
                            <tr key={indicator.id}>
                                <td>{i + 1}</td>
                                <td>
                                    {indicator.label[i18n.language] ??
                                        indicator.label['sv']}
                                    ,{' '}
                                    {indicator.subLabel[i18n.language] ??
                                        indicator.subLabel['sv']}
                                </td>
                                <td>
                                    {format(
                                        formatsTooltip[indicator.unitFormat]
                                    )(
                                        hovered?.properties?.values[
                                            indicator.id
                                        ][year]
                                    )}{' '}
                                    {indicator.unitDisplay ?? ''}
                                </td>
                                <td>
                                    {kommun &&
                                        kommun.properties?.values[
                                            indicator.id
                                        ]?.[year] && (
                                            <>
                                                {format(
                                                    formatsTooltip[
                                                        indicator.unitFormat
                                                    ]
                                                )(
                                                    kommun.properties?.values[
                                                        indicator.id
                                                    ][year]
                                                )}{' '}
                                                {indicator.unitDisplay ?? ''}
                                            </>
                                        )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
