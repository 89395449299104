import {
    Feature,
    FeatureCollection,
    GeoJsonProperties,
    Geometry,
    Point,
    Polygon,
} from 'geojson';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { polygonsToPoints } from '../helpers/polygons-to-points';
import { Indicator, DataOptions, GeoLevel, LowPopAreas } from '../types';
import lowPopAreasJSON from '../lowPopAreas.json';

const lowPopAreas = lowPopAreasJSON as LowPopAreas;

export type Data = {
    points: Feature<Point, GeoJsonProperties>[];
    polygons: FeatureCollection;
    options: DataOptions;
};

const polygonUrls = {
    deso: '/data/stockholm_deso_coordinateprecision_6.geojson',
    regso: '/data/stockholm_regso_coordinateprecision_6.geojson',
    kommun: '/data/kommuner_01.geojson',
    nyko6: `${
        import.meta.env.VITE_API_URL
    }/api/v1/data/nyko6_polygons.geojson?filterGeoId=01`,
};
async function fetchPolygons(
    level: GeoLevel
): Promise<FeatureCollection<Polygon>> {
    const url = polygonUrls[level];
    const request = await fetch(url);
    const json = await request.json();
    return json;
}

async function fetchTable(url: string) {
    const request = await fetch(url, {
        credentials: 'include',
    });
    const text = await request.text();
    const json = text.match(/.+/g)?.map((row: string) => JSON.parse(row)) ?? [];
    return json;
}

function findFeatureInTable(
    table: any[],
    feature: Feature<Geometry, GeoJsonProperties>,
    geoLevel: GeoLevel
): any | null {
    const match = table.find((d) => {
        if (geoLevel === 'deso') {
            return d.id === feature.properties?.deso;
        }
        if (geoLevel === 'regso') {
            if (d.id === feature.properties?.regso) return true;
            if (d.id === feature.properties?.regsokod) return true;
            return false;
        }
        if (geoLevel === 'kommun') {
            if (+d.id === feature.properties?.KOMMUNKOD) return true;
            if (d.id === feature.properties?.kommunnamn) return true;
            if (d.id === feature.properties?.KOM_KOD) return true;
            return false;
        }
        if (geoLevel === 'nyko6') {
            return d.id === feature.properties?.geoId;
        }
    });

    if (!match) {
        return null;
    }

    return match;
}

function getFeatureId(
    feature: Feature<Geometry, GeoJsonProperties>,
    geoLevel: GeoLevel
): any | null {
    if (geoLevel === 'deso') {
        return feature.properties?.deso;
    }
    if (geoLevel === 'regso') {
        return feature.properties?.regsokod ?? feature.properties?.regso;
    }
    if (geoLevel === 'kommun') {
        return String(feature.properties?.KOMMUNKOD);
    }
    if (geoLevel === 'nyko6') {
        return feature.properties?.geoId;
    }
}

function addValuesToProperties(
    features: Feature<Geometry>[],
    table: any[],
    indicator: Indicator,
    geoLevel: GeoLevel,
    totalTable: any[] | null,
    totalValueKey: string | null
): Feature<Geometry>[] {
    const featuresWithValues = features.map((f, i) => {
        const match = findFeatureInTable(table, f, geoLevel);
        if (!match) {
            console.error('Failed to find match for feature', f);
            return null;
        }

        // Calculate share of total if totalTable is provided
        let shareValues: any | null = null;
        if (totalTable && totalValueKey) {
            const totalMatch = findFeatureInTable(totalTable, f, geoLevel);
            if (!totalMatch) return null;

            const partValues = match.values[indicator.valueKey];
            const totalValues = totalMatch.values[totalValueKey];
            shareValues = Object.entries(partValues).reduce(
                (obj, [year, value]) => {
                    if (totalValues[year] === 0) {
                        obj[year] = 0;
                    } else {
                        obj[year] = Number(
                            (value as number) / totalValues[year]
                        );
                    }
                    return obj;
                },
                {} as any
            );
        }

        const indicatorValues = shareValues ?? match.values[indicator.valueKey];

        if (lowPopAreas[match.id]) {
            const { yearsWithLowPop } = lowPopAreas[match.id];
            yearsWithLowPop.forEach((year) => {
                indicatorValues[year] = null;
            });
        }

        const newValues = {
            ...(f.properties?.values ?? {}),
            [indicator.id]: indicatorValues,
        };

        return {
            ...f,
            properties: {
                id: getFeatureId(f, geoLevel),
                ...f.properties,
                values: newValues,
            },
        };
    });

    return featuresWithValues.filter((f) => f) as Feature<Geometry>[];
}

export async function getData(
    options: DataOptions,
    indicatorsMap: Record<string, Indicator>
): Promise<Data> {
    const geojson = await fetchPolygons(options.geoLevel);

    let dataPolygons = geojson.features;

    for (const indicator of Object.values(options.indicators)) {
        if (!indicator) continue;

        const tableUrl = indicator.urls[options.geoLevel];
        const table = await fetchTable(tableUrl);
        let totalTable: any[] | null = null;
        let totalValueKey: string | null = null;
        if (indicator.totalId) {
            const totalTableUrl =
                indicatorsMap[indicator.totalId].urls[options.geoLevel];
            totalValueKey = indicatorsMap[indicator.totalId].valueKey;
            totalTable = await fetchTable(totalTableUrl);
        }

        dataPolygons = addValuesToProperties(
            dataPolygons,
            table,
            indicator,
            options.geoLevel,
            totalTable,
            totalValueKey
        ) as Feature<Polygon>[];
    }

    const dataFeatures = polygonsToPoints(dataPolygons);

    if (
        !dataFeatures ||
        dataFeatures.length === 0 ||
        !geojson ||
        !dataPolygons
    ) {
        console.error('Failed to get everything');
        return Promise.reject('Failed to get everything');
    }

    geojson.features = dataPolygons;

    return {
        points: dataFeatures as Feature<Point, GeoJsonProperties>[],
        polygons: geojson,
        options,
    };
}

export function useDataQuery(
    targetOptions: DataOptions,
    indicatorsMap: Record<string, Indicator>
) {
    return useQuery({
        queryKey: ['data', targetOptions],
        queryFn: () => getData(targetOptions, indicatorsMap),
        placeholderData: keepPreviousData,
    });
}
